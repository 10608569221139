$rush-font: "TradeGothicLT-CondEighteen";
$rush-font-bold: "TradeGothicLT-BoldCondTwenty";
$rush-font-brand: "Warnock W08 Regular";
$enable-validation-icons: false;

$rush-gold: #eeb111;
$rush-header: #ffdc8e;
$rush-header-light: #ffebb7;
$rush-price: #fffcf3;
$rush-red: #ed1c24;
$rush-black: #111111;
$rush-dark: #4b4b4b;
$rush-light: #e7e7e8;
$rush-green: #42a948;

$theme-colors: (
  "primary": $rush-gold,
  "secondary": $rush-black,
  "header": $rush-header,
  "price": $rush-price,
  // "white-with-mouseover": white,
  "warning": #f79246,
  "danger": $rush-red,
  "dark": $rush-dark,
  "medium": #ccc,
  "light": $rush-light,
  "light2": #f5f5f5,
  "green": $rush-green,
);

$sizes: (
  33: 33%,
  66: 66%,
);

$body-bg: #fff;

$badge-font-size: inherit;
$border-color: #ccc;
$nav-tabs-border-color: $border-color;
$nav-tabs-link-active-border-color: $nav-tabs-border-color
  $nav-tabs-border-color $body-bg;
$navbar-dark-color: #fff;
$navbar-dark-hover-color: #888;
$navbar-dark-disabled-color: $rush-dark;
$tooltip-bg: $rush-light;
$tooltip-opacity: 1;

$fa-font-path: "/assets/fontawesome/webfonts";
