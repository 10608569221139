#five9-maximize-button {
    background: #eeb111;
    color: black;
}

#five9-minimize-button {
    background: #eeb211 !important;
}

.five9-frame {
    bottom: 45px !important;
    z-index: 1029 !important;
}

@media only screen and (max-width: 767px) {
    .five9-frame {
        bottom: 64px !important;
    }
}

.five9-chat-button .five9-icon {
    background-image: url(../img/chat-small-black.png) !important;
}

.five9-frame #five9-minimize-icon {
    background-image: url(../img/minus-32-black.png) !important;
}

.five9-frame #five9-popout-button {
    background-image: url(../img/external-link-32-black.png) !important;
}

.five9-chat-button {
    color: black !important;
    font-family: 'TradeGothicLT-CondEighteen' !important;
}

.fixed-top {
    z-index: 1028 !important;
}