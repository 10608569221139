$font-url : '../fonts/';
@font-face {
  font-family: 'TradeGothicLT';
  src: url($font-url + 'trade-gothic/2F4C1A_0_0.eot');
  src: url($font-url + 'trade-gothic/2F4C1A_0_0.eot?#iefix') format('embedded-opentype'),
  url($font-url + 'trade-gothic/2F4C1A_0_0.woff2') format('woff2'),
  url($font-url + 'trade-gothic/2F4C1A_0_0.woff') format('woff'),
  url($font-url + 'trade-gothic/2F4C1A_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'TradeGothicLT-Oblique';
  src: url($font-url + 'trade-gothic/2F4C1A_1_0.eot');
  src: url($font-url + 'trade-gothic/2F4C1A_1_0.eot?#iefix') format('embedded-opentype'),
  url($font-url + 'trade-gothic/2F4C1A_1_0.woff2') format('woff2'),
  url($font-url + 'trade-gothic/2F4C1A_1_0.woff') format('woff'),
  url($font-url + 'trade-gothic/2F4C1A_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'TradeGothicLT-BoldTwo';
  src: url($font-url + 'trade-gothic/2F4C1A_2_0.eot');
  src: url($font-url + 'trade-gothic/2F4C1A_2_0.eot?#iefix') format('embedded-opentype'),
  url($font-url + 'trade-gothic/2F4C1A_2_0.woff2') format('woff2'),
  url($font-url + 'trade-gothic/2F4C1A_2_0.woff') format('woff'),
  url($font-url + 'trade-gothic/2F4C1A_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'TradeGothicLT-BoldTwoOblique';
  src: url($font-url + 'trade-gothic/2F4C1A_3_0.eot');
  src: url($font-url + 'trade-gothic/2F4C1A_3_0.eot?#iefix') format('embedded-opentype'),
  url($font-url + 'trade-gothic/2F4C1A_3_0.woff2') format('woff2'),
  url($font-url + 'trade-gothic/2F4C1A_3_0.woff') format('woff'),
  url($font-url + 'trade-gothic/2F4C1A_3_0.ttf') format('truetype');
}

@font-face {
  font-family: $rush-font;
  src: url($font-url + 'trade-gothic/2F4C1B_0_0.eot');
  src: url($font-url + 'trade-gothic/2F4C1B_0_0.eot?#iefix') format('embedded-opentype'),
  url($font-url + 'trade-gothic/2F4C1B_0_0.woff2') format('woff2'),
  url($font-url + 'trade-gothic/2F4C1B_0_0.woff') format('woff'),
  url($font-url + 'trade-gothic/2F4C1B_0_0.ttf') format('truetype');
}

@font-face {
  font-family: $rush-font-bold;
  src: url($font-url + 'trade-gothic/2F4C1B_1_0.eot');
  src: url($font-url + 'trade-gothic/2F4C1B_1_0.eot?#iefix') format('embedded-opentype'),
  url($font-url + 'trade-gothic/2F4C1B_1_0.woff2') format('woff2'),
  url($font-url + 'trade-gothic/2F4C1B_1_0.woff') format('woff'),
  url($font-url + 'trade-gothic/2F4C1B_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'TradeGothicLTCom-Cn18Obl';
  src: url($font-url + 'trade-gothic/2F4C1B_2_0.eot');
  src: url($font-url + 'trade-gothic/2F4C1B_2_0.eot?#iefix') format('embedded-opentype'),
  url($font-url + 'trade-gothic/2F4C1B_2_0.woff2') format('woff2'),
  url($font-url + 'trade-gothic/2F4C1B_2_0.woff') format('woff'),
  url($font-url + 'trade-gothic/2F4C1B_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'TradeGothicLTCom-BdCn20Obl';
  src: url($font-url + 'trade-gothic/2F4C1B_3_0.eot');
  src: url($font-url + 'trade-gothic/2F4C1B_3_0.eot?#iefix') format('embedded-opentype'),
  url($font-url + 'trade-gothic/2F4C1B_3_0.woff2') format('woff2'),
  url($font-url + 'trade-gothic/2F4C1B_3_0.woff') format('woff'),
  url($font-url + 'trade-gothic/2F4C1B_3_0.ttf') format('truetype');
}

b, strong, .strong {
	font-family: $rush-font-bold;
	font-weight: normal;
}
