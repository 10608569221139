.navbar-brand {
  font-family: $rush-font-brand;
  font-size: 30px;
}

.navbar-dark {
  .navbar-text {
    color: #9d9d9d;
  }
}
