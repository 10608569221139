// Gutters - creating spacing *between* elements

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
        @each $size, $length in $spacers {
            .gx#{$infix}-#{$size} > * {
                &:not(:first-child) {
                    margin-left: $length !important;
                }
                &:not(:last-child) {
                    margin-right: $length !important;
                }
            }
            .gy#{$infix}-#{$size} > * {
                &:not(:first-child) {
                    margin-top: $length !important;
                }
                &:not(:last-child) {
                    margin-bottom: $length !important;
                }
            }
        }
    }
}
  