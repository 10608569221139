  html {
    font-size: 13px;
  }

  body {
    background-image: url(../img/body_bg.png);
    background-color: #a9a9a9;
    background-position: top left;
    background-repeat: repeat-x;
    background-size: 150px 150px;
    font-family: $rush-font;
  }

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    height: 100px;
    margin: 0 15px;
    width: 50%;
  }

  .upload-btn-wrapper .btn {
    border: 2px solid gray;
    color: gray;
    background-color: #fff;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    width: 100%;
    height: 100px;
  }

  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .add-file {
    border: 2px solid gray;
    display: inline-block;
    padding: 10px;
    color: gray;
    background-color: #fff;
    padding: 8px 20px;
    border-radius: 8px;
    width: 100%;
    height: 100px;
    vertical-align: middle;
  }

  .refresh-data-text {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    position: relative;
    top: -5px;
  }

  a:focus,
  a:hover {
    color: #111;
    text-decoration: none;
  }

  .float-left {
    float: left;
    width: 100%;
  }

  .btn-width-160 {
    width: 160px;
  }

  .bold {
    font-weight: 400;
  }

  .fix-search {
    background: #f5f5f5 none repeat scroll 0 0;
    padding: 5px 15px 20px;
    top: 75px;
    z-index: 10;
  }

  .padding-0 {
    padding: 0 !important;
  }

  .padding-t-b-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .margin-t-10 {
    margin-top: 10px;
  }

  .padding-t-b-15 {
    padding: 15px 0;
  }

  .padding-t-10 {
    padding-top: 10px;
  }

  .padding-t-20 {
    padding-top: 20px;
  }

  .padding-b-10 {
    padding-bottom: 10px;
  }

  .padding-l-5 {
    padding-left: 5px;
  }

  .margin-r-5 {
    margin-right: 5px;
  }

  .margin-t-10 {
    margin-top: 10px;
  }

  .margin-t-10 {
    margin-top: 10px;
  }

  .standard-btn-inactive {
    background-color: #ebebeb;
    color: #ccc;
    font-size: 13px;
  }

  .standard-btn {
    background-color: #111;
    color: #fff;
    font-size: 13px;
    padding: 5px 15px;
    border: none;
  }

  .standard-btn:hover {
    background-color: #ccc;
    color: #111;
    border: none;
  }

  .yellow-btn {
    margin-left: 3px;
    background-color: #eeb111 !important;
    color: #111;
    font-size: 14px;
    border: none;
    height: 32px;
    font-family: TradeGothicLT-BoldCondTwenty;
  }

  .remove-margin {
    margin-left: 0px !important;
  }

  .yellow-btn:hover {
    background-color: #eeb111;
    border: none;
  }

  .yellow-btn:focus {
    background-color: #eeb111;
    border: none;
  }

  .yellow-btn .active:active {
    background-color: #eeb111;
    border: none;
  }

  .yellow-btn:active:focus {
    background-color: #eeb111;
    border-color: #eeb111;
    color: #111;
  }

  .black-btn {
    background-color: #111;
    color: #fff;
    font-size: 14px;
    border: none;
    font-family: TradeGothicLT-BoldCondTwenty;
  }

  .black-btn:hover {
    background-color: #111;
    border: none;
    color: #fff;
  }

  .black-btn:focus {
    background-color: #111;
    border: none;
    color: #fff;
  }

  .black-btn .active:active {
    background-color: #111;
    border: none;
  }

  .hyperlink {
    color: #111;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;

      &:not(:disabled) {
        text-decoration-color: $rush-gold;
        cursor: pointer;
      }
    }
  }

  .hyperlink-success {
    color: #28a745;
    background-color: transparent;
    border-color: transparent;

    &.disabled {
      pointer-events: none;
      cursor: default;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .customer-search .form-group {
    margin-bottom: 0;
  }

  .customer-search .customer-name {
    padding: 10px 15px 10px 15px;
  }

  .customer-search .customer-name .dropdown a.dropdown-toggle {
    color: #292929;
    margin: 0 12px;
  }

  .customer-search .customer-name .dropdown a.dropdown-toggle .caret {
    margin-left: 12px;
  }

  .customer-search .customer-search {
    padding: 5px 15px 5px 15px;
  }

  .my-cart-btn {
    padding: 9px 20px 9px 15px !important;
    border: 0;
    top: 2px;
  }

  .my-cart-btn span {
    position: absolute;
    padding: 3px;
    border-radius: 20px;
    background: #ed1c24;
    font-size: 11px;
    color: #fff;
    top: 16px;
    min-width: 20px;
  }

  .parts .form-control-feedback {
    color: #ccc;
    margin: 9px 0;
  }

  .parts .customer-search-textbox {
    padding-right: 30px;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    line-height: normal;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(../img/select_dropdown.png) !important;
    background-position: 96% 14px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding-right: 20px;
    text-indent: 1px;
    -o-text-overflow: "";
    text-overflow: "";
  }

  select option {
    text-align: left;
    padding: 5px;
    border: 0 solid #ccc;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .title-of-box-hold {
    display: flex;
    justify-content: space-between;
    background: $rush-black;
    height: 44px;
    padding: 11px 18px;
    border-radius: 5px 5px 0 0;
    color: #fff;
  }

  .product-card {
    border: 1px solid transparent;

    .product-card-title {
      background-color: $rush-header;
    }

    &:hover .product-card-title {
      background-color: $rush-gold;
    }

    &.disabled-card .product-card-title {
      background-color: theme-color("medium");
    }

    &:hover {
      border-color: #000 !important;
    }
  }

  .product-card-title {

    // Self and anchor descendents
    &,
    & a {
      color: #000;
    }
  }

  .product-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .product-image-feedback {
      position: absolute;
      right: 0;
      bottom: 10%;
      display: none;
    }

    &:hover .product-image-feedback {
      display: block;
    }
  }

  .product-image-md {
    height: 190px;

    >img {
      width: 100px;
      height: 100px;
    }
  }

  .product-image-lg {
    height: 280px;

    >img {
      width: 280px;
      height: 280px;
    }
  }

  .product-image-sm {
    height: 125px;

    >img {
      width: 100px;
      height: 100px;
    }
  }

  input[type="checkbox"]:not(old),
  input[type="radio"]:not(old) {
    display: none;

    +label:before {
      content: "\00a0"; // non-breaking whitespace
      background-color: #fff;
      background-image: none;
      cursor: pointer;
      display: inline-flex;
      margin-right: 0.25rem;
      border: 1px solid silver;
      width: 18px;
      height: 18px;
      align-items: center;
      justify-content: center;
      font-size: 0.875em;
      color: #000;
      text-shadow: 0 0 0.0714em #73994d;
    }

    &:checked+label:before {
      content: "\2714"; // checkmark
      background-image: -o-linear-gradient(#e0e0e0, #f0f0f0);
      background-image: -webkit-linear-gradient(#e0e0e0, #f0f0f0);
      background-image: -webkit-gradient(linear,
          left top,
          left bottom,
          from(#e0e0e0),
          to(#f0f0f0));
      background-image: linear-gradient(#e0e0e0, #f0f0f0);
    }

    &:disabled+label:before {
      background-color: #ccc;
      background-image: none;
      cursor: initial;
    }
  }

  input[type="radio"]:not(old) {
    +label {
      position: relative;
      margin-left: 1.5rem;
    }

    +label:before {
      display: block;
      position: absolute;
      border-radius: 50%;
      left: -1.5rem;
      top: 0;
    }

    &:checked+label:before {
      border-color: #696969;
      content: "\00a0"; // non-breaking whitespace
    }

    &:checked+label:after {
      position: absolute;
      display: block;
      top: 0;
      left: -1.5rem;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23696969'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70% 70%;
      border: 1px solid transparent;
      //background-color: #696969;
      content: "\00a0"; // non-breaking whitespace
    }
  }

  .breadcrumb a {
    color: #292929;
  }

  .breadcrumb .active {
    color: #000;
  }

  .search-holder {
    background-color: f5f5f5;
    width: 100%;
  }

  .search-holder .sub-title {
    margin-top: 12px;
    font-size: 16px;
    font-family: $rush-font-bold;
  }

  .search-holder h5 {
    height: 17px;
  }

  .search-holder label {
    font-weight: 400;
  }

  .search-holder .cust-form-holder {
    margin-bottom: 11px;
    float: left;
    width: 100%;
  }

  .search-holder .form-group.form-inline {
    margin-bottom: 0;
  }

  .heading-search {
    background-color: #fff;
    padding-top: 15px;
  }

  .heading-users {
    background-color: #fff;
    padding-top: 15px;
  }

  .favorites-customer-list {
    max-height: 370px;
    min-height: 40px;
    overflow-y: auto;
  }

  .list-grid {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;

    &:hover {
      background-color: $rush-header-light;
    }
  }

  .pagination-result {
    display: block;
    margin: 14px 0 0;
    border-right: 1px solid #ccc;
    padding: 0 15px;
  }

  .table th {
    font-weight: 400;
  }

  .table:not(.no-rollover) tbody tr:hover {
    background-color: $rush-header-light;
  }

  .tbody tbody:hover {
    background-color: $rush-header-light;
  }

  .customer-table {
    max-height: 491px;
    min-height: 40px;
    overflow-y: auto;
  }

  .range-text-box {
    position: relative;
    width: 75% !important;
  }

  .renge-top {
    background-image: url(../img/add_qty.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    height: 17px;
    width: 24px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  .renge-top:hover {
    background-image: url(../img/add_qty_active.png);
  }

  .renge-down {
    background-image: url(../img/sub_qty.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    height: 17px;
    width: 24px;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  .renge-down:hover {
    background-image: url(../img/sub_qty_active.png);
  }

  .my-cart-list {
    position: absolute;
    right: 0;
    top: 42px;
    width: 595px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-top: 3px solid $rush-gold;
    padding: 10px;
  }

  .customer-search .dropdown {
    display: inline-block;
  }

  .inventory-search-icon .customer-search-textbox {
    width: 100%;
    margin-bottom: 20px;
  }

  .inventory-search-icon .form-control-feedback {
    color: #000;
    right: 15px;
    top: 2px;
  }

  .inventory-search-icon .inventory-search-icon .inventory-search-table {
    float: left;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
  }

  .inventory-search-table .table>tbody>tr>td {
    vertical-align: middle !important;
  }

  .my-cart-list-table .table {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
  }

  .my-cart-list-table .table>tbody>tr>td,
  .my-cart-list-table .table>tbody>tr>th,
  .my-cart-list-table .table>tfoot>tr>td,
  .my-cart-list-table .table>tfoot>tr>th,
  .my-cart-list-table .table>thead>tr>td,
  .my-cart-list-table .table>thead>tr>th {
    border: 0;
  }

  .my-cart-list-table .remove {
    font-size: 13px;
    text-decoration: none;
    width: 80px;
  }

  .my-cart-list-table .remove a {
    color: #000;
  }

  .my-cart-list-table .quantity {
    width: 160px;
  }

  .my-cart-list-table .quantity .range-text-box {
    padding-right: 22%;
  }

  .my-cart-list-table .table tr:hover {
    background-color: transparent;
  }

  .my-cart-list-table .range-text-box .form-control {
    padding: 3px;
  }

  .my-cart-list-table .quantity .range-text-box {
    padding-right: 15%;
  }

  .my-cart-list-table .row-heading {
    background: #c1c1c1;
    padding: 2px 10px;
  }

  .bold-font {
    font-weight: 700;
    font-size: 18px;
  }

  .billing-table .table {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
  }

  .billing-table .table>thead>tr>th {
    border-bottom: 0 solid #ddd;
  }

  .billing-table .table>tbody>tr>td,
  .billing-table .table>tbody>tr>th,
  .billing-table .table>tfoot>tr>td,
  .billing-table .table>tfoot>tr>th,
  .billing-table .table>thead>tr>td,
  .billing-table .table>thead>tr>th {
    border-bottom: 1px solid transparent !important;
  }

  .billing-table .table>tbody>tr>td span,
  .billing-table .table>tbody>tr>th span,
  .billing-table .table>tfoot>tr>td span,
  .billing-table .table>tfoot>tr>th span,
  .billing-table .table>thead>tr>td span,
  .billing-table .table>thead>tr>th span {
    font-size: 11px;
  }

  .billing-table .table tr {
    background-color: transparent;
    font-weight: 400;
  }

  .billing-table .billing-right {
    border-bottom: 1px solid #ccc !important;
  }

  .billing-table hr {
    border-bottom: 1px solid #ccc !important;
  }

  .oredr-holder {
    display: flex;
  }

  .oredr-holder .top-title {
    background: #eeb111 none repeat scroll 0 0 !important;
    border-radius: 5px 5px 0 0;
    color: #000;
    font-size: 18px;
    height: 44px;
    padding: 11px 18px;
    margin: 20px 0;
  }

  .oredr-holder span {
    font-size: 16px;
    font-weight: 400;
    margin: 0 1px;
  }

  .oredr-holder .text-align-right {
    text-align: right;
  }

  .oredr-holder .text-align-left {
    text-align: left;
    margin: 40px 0 40px 0;
  }

  .oredr-holder .text-align-left h1 {
    font-size: 18px;
  }

  .ShowHideLoadingPanel {
    background-color: #fff;
    z-index: 99999999;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: fixed;
    opacity: 0.7;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
  }

  .loadingImage {
    left: 50%;
    top: 50%;
    position: absolute;
    z-index: 99999;
    height: 100px;
    width: 100px;
    margin-left: -16px;
    margin-top: -16px;
  }

  // Apply Bootstrap validation styling based on Angular validation classes
  // Requires ancestor with .was-validated
  .ng-valid {
    @extend :valid;
  }

  .ng-invalid {
    @extend :invalid;
  }

  .strikethrough {
    text-decoration: line-through;
  }

  #toaster {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: fixed;
    top: 10px;
    z-index: 9999;
    bottom: 0;
    margin-bottom: 22px;
    margin-right: 15px;
    width: 100%;
    max-width: 100%;
    max-height: 0;
  }

  .tabs-link-list {
    border: 0;
  }

  .tabs-link-list>li,
  .tabs-link-list>li.active {
    padding: 0 !important;
    margin-top: -5px;
    border: 0 !important;
  }

  .tabs-link-list>li.active>a,
  .tabs-link-list>li>a {
    padding: 0 !important;
    border: 0 !important;
  }

  .tabs-link-list>li>a:hover {
    background: 0 0 !important;
  }

  .range-text-box .form-control {
    width: 75%;
    font-family: $rush-font;
    font-size: 15px;
  }

  .header-customer-search form {
    padding: 0 15px 15px 15px;
    min-width: 385px;
  }

  .bottom-border {
    border-bottom: 1px solid #ccc;
  }

  .price-tag {
    background-image: url(../img/price_adj_inactive.png) !important;
    background-position: 0 -1px;
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    background-size: 15px auto;
    display: block;
  }

  .checkout-customer-header>h2 {
    margin: 10px 0 0 15px;
    display: inline-block;
  }

  .checkout-customer-header>h5 {
    margin-top: 10px;
    display: inline-block;
  }

  .table-scroll {
    overflow-y: auto;
    width: 100%;
  }

  .btn-default.disabled.focus,
  .btn-default.disabled:focus,
  .btn-default.disabled:hover,
  .btn-default[disabled].focus,
  .btn-default[disabled]:focus,
  .btn-default[disabled]:hover,
  fieldset[disabled] .btn-default.focus,
  fieldset[disabled] .btn-default:focus,
  fieldset[disabled] .btn-default:hover {
    background-color: #eeb111;
    border-color: #000;
  }

  span.countLoader {
    background-image: url(../img/countLoader.gif) !important;
    height: 22px;
    width: 22px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% auto;
    right: 7px !important;
    display: inline-block;
  }

  span.mycart-copun-Loader {
    position: absolute;
    right: -28px !important;
    top: 6px;
  }

  .cart-price-override .modal-content {
    padding: 15px 0;
  }

  .cart-price-override input {
    margin-bottom: 8px;
  }

  .form-control-removed {
    text-decoration: line-through;
  }

  .order-confirmation-btn {
    text-align: center;
    margin-bottom: 10px;
  }

  .order-confirmation-btn>span>a {
    font-size: 16px;
  }

  .cart-price-override .modal-content {
    padding: 15px 0;
  }

  .order-confirmation-table-scroll {
    overflow-y: auto;
    width: 100%;
  }

  .cart-price-override input {
    margin-bottom: 8px;
  }

  .product-tabs {
    min-height: 300px;
  }

  .header-user-branch {
    width: 50%;
    text-align: right;
    margin: 22px 0;
  }

  .header-user-branch a {
    color: #fff;
  }

  .suggestions {
    background: #fff;
    position: absolute;
    border: 0;
    max-height: 310px;
    overflow-y: auto;
    width: 250px;
    right: 0;
    text-align: left;
    top: 20px;
    border: 1px solid #ccc;
  }

  .suggestions a {
    color: #337ab7 !important;
    cursor: pointer;
    margin-left: 10px;
  }

  .suggestions div:hover {
    background-color: $rush-header-light;
    cursor: pointer;
  }

  .relevance-percentage {
    background: #ed1c24;
    color: #fff;
    margin: 5px 15px 13px 17px;
  }

  .core-options {
    height: 28px !important;
    padding: 4px 8px !important;
    font-size: 11px !important;
    background-position: 96% 11px !important;
  }

  .quantity-greenColor {
    color: green;
  }

  .quantity-redColor {
    color: red;
  }

  .favourite-card {
    min-height: 185px;
  }

  .hidden-visibility {
    visibility: hidden !important;
  }

  .right-text-align {
    text-align: right !important;
  }

  .left-text-align {
    text-align: left !important;
  }

  .button-icon {
    background: 0 0 !important;
    border: none;
    padding: 0 !important;
    font: inherit;
    cursor: pointer;
    color: #337ab7;
  }

  .button-icon:hover {
    color: #000;
  }

  .button-icon:disabled {
    color: #c3c3c3;
  }

  .order-salesperson {
    margin: 0 !important;
    font-weight: 700 !important;
  }

  .padding-l-10 {
    padding-left: 10px;
  }

  .search-border-right {
    border-left: 1px solid #ccc;
    padding-left: 10px;
  }

  .search-holder h5 {
    height: 14px;
    margin-top: 6px;
    margin-bottom: 15px;
  }

  .dropdown a {
    color: #000;
  }

  .switchcartdisabled {
    pointer-events: none;
    cursor: not-allowed !important;
    color: #d3d3d3;
  }

  a.switchcartdisabled {
    border: none !important;
  }

  .disabledresult {
    pointer-events: none;
    cursor: not-allowed;
  }

  .input-icon {
    position: relative;
  }

  .currency-sign {
    font-size: 13px !important;
  }

  .tabs-link-list>li.active {
    padding-bottom: 11px !important;
  }

  .btn-primary.disabled.focus,
  .btn-primary.disabled:focus,
  .btn-primary.disabled:hover,
  .btn-primary[disabled].focus,
  .btn-primary[disabled]:focus,
  .btn-primary[disabled]:hover,
  fieldset[disabled] .btn-primary.focus,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary:hover {
    background-color: #4e4e4e;
    border-color: #4e4e4e;
  }

  .table>tbody>tr>td.paddingreset,
  .table>tbody>tr>th.paddingreset,
  .table>tfoot>tr>td.paddingreset,
  .table>tfoot>tr>th.paddingreset,
  .table>thead>tr>td.paddingreset,
  .table>thead>tr>th.paddingreset {
    padding: 10px 0 0 !important;
    width: 10px;
  }

  .checkout-delivery-loader {
    background-image: url(../img/countLoader.gif) !important;
    height: 22px;
    width: 20px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% auto;
    right: 7px !important;
    display: block;
    margin: 2px 0;
    position: absolute;
  }

  .checkout-cart-price {
    padding: 0 0;
  }

  .estimated-textbox {
    height: 25px;
    padding: 0 3px;
  }

  .estimated-delete {
    display: block;
    font-size: 20px !important;
    margin-left: 3px !important;
    position: absolute;
  }

  .checkout-price-tag {
    position: absolute !important;
  }

  .chart-container {
    padding: 0 10px 5px 10px;
    border-bottom: 1px solid #ccc;
  }

  .relevance-text-container {
    position: relative;
    top: -7px;
  }

  .relevance-percent {
    color: #eeb111;
  }

  .modal-open {
    overflow: hidden !important;
    padding-right: 0 !important;
  }

  .padding-b-15 {
    padding-bottom: 15px !important;
  }

  .body-padding {
    padding: 0 0 30px 0 !important;
  }

  #panelAlternateParts,
  #panelRelatedParts {
    position: relative;
  }

  .related-alternate-parts-pagination {
    position: absolute;
    right: 0;
    top: -45px;
  }

  .full-width {
    width: 100%;
    float: left;
  }

  .remove-borderradius-r-b {
    border-radius: 4px 0 0 4px;
  }

  .remove-borderradius-l-b {
    border-radius: 0 4px 4px 0;
  }

  .more-btn {
    background: #ccc none repeat scroll 0 0;
    border-radius: 50%;
    color: #fff;
    margin: -2px 0 0;
    padding: 5px;
    cursor: pointer;
  }

  .options-list li {
    padding: 5px 0;
  }

  .options-list .lastlink {
    margin-bottom: 4px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

  .options-list li a {
    color: #000 !important;
    cursor: pointer;
    font-size: 13px;
    font-family: $rush-font;
  }

  .options-list li a:hover {
    color: #eeb111 !important;
    cursor: pointer;
  }

  .tab-table li a {
    color: #555;
    cursor: default !important;
    background-color: #fff !important;
    border: 1px solid #ddd !important;
    border-top: 2px solid #eeb111 !important;
    border-radius: 0;
  }

  .order td h1 {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 18px;
    text-align: right;
  }

  .order th {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 18px;
    text-align: right;
    background-color: transparent;
  }

  .order td {
    background-color: transparent;
    border-width: 0 !important;
  }

  .order tr {
    background-color: transparent !important;
    border-width: 0 !important;
  }

  .more-btn:hover {
    background-color: #eeb111;
  }



  .popover-content {
    padding: 5px 10px 5px !important;
  }

  .order-billing-summary-text {
    text-align: left;
    line-height: 23px !important;
  }

  .notification-count {
    display: inline-block;
    background: #eeb111;
    border-radius: 20px;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    font-size: 12px;
    font-weight: 800;
    line-height: 1.6em;
    min-width: 20px;
    text-align: center;
  }

  .red-notification-count {
    background: #f30000;
    padding: 1px 3px;
    color: #fff;
    font-size: 12px;
    border-radius: 20px;
    min-width: 15px;
    text-align: center;
    margin-left: 5px;
  }

  .notification-icon {
    font-size: 30px;
  }

  .notifications {
    min-width: 250px;
  }

  .notifications-wrapper {
    overflow: auto;
    max-height: 265px;
  }

  .notification-footer,
  .notification-heading {
    padding: 2px 10px;
  }

  .dropdown-menu.divider {
    margin: 5px 0;
  }

  .item-title {
    font-size: 1.3rem;
    color: #000;
    margin: 0;
  }

  .notifications div.content a {
    text-decoration: none;
    background: #fff;
    border-bottom: 1px solid #eee;
    float: left;
    width: 100%;
  }

  .notifications div.content:last-child a {
    border-bottom: 0 solid #eee;
  }

  .notification-item {
    padding: 10px 10px;
    margin: 0;
    border-radius: 0;
  }

  .notification-item p {
    font-size: 12px;
    color: #969595;
    margin: 0;
  }

  .unread-notification {
    border-right: solid 4px #eeb111 !important;
    background-color: #fffaea;
  }

  .user-sales-metrics .popover {
    max-width: 400px !important;
  }

  .user-data-table .col-md-3 {
    padding-right: 0;
  }

  branch ngb-typeahead-window.dropdown-menu.show {
    max-height: 200px;
    overflow: auto;
  }

  .notification-heading {
    border-bottom: 1px solid #ccc;
  }

  .notification-footer {
    border-top: 1px solid #ccc;
  }

  .close-icon {
    position: absolute;
    top: 2px;
    font-size: 30px;
    right: 28px;
    color: #000;
    opacity: 1;
  }

  .table-bordered-between {

    >tbody>tr>td,
    >tr>td {
      &:not(:last-child()) {
        border-right: 1px solid #000;
      }
    }
  }

  .header-user-branch {
    margin-bottom: 0 !important;
    max-height: 50px !important;
  }

  .pager-header-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .padding-l-0 {
    padding-left: 0 !important;
  }

  span.notificationCountLoader {
    background-image: url(../img/countLoader.gif) !important;
    height: 22px;
    width: 22px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% auto;
    right: 2px !important;
    position: absolute;
    border-radius: 20px;
    z-index: 9999999;
  }

  .purchaseOrderCheckbox {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 6px;
  }

  .create-part-div {
    background: #fff;
    padding: 10px;
    margin: 0;
  }

  .user-entity-roles-table {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 10px;
    padding-right: 10px;
  }

  .price-verify {
    background: #fff;
    border: 1px solid #989494;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
    padding: 10px 0;
    text-align: center;
  }

  .price-verify-msc {
    background: #fff;
    border: 1px solid #989494;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
    padding: 10px 0;
    margin-top: 10px;
    text-align: center;
  }

  .price-verify b,
  .price-verify-msc b {
    font-size: 15px;
  }

  .price-verify-icon {
    background: #000 none repeat scroll 0 0;
    border-radius: 80%;
    color: #fff;
    font-size: 13px;
    height: 18px;
    line-height: 15px;
    padding: 0;
    text-align: center;
    width: 18px;
    cursor: default !important;
    position: relative;
    top: -1px;
    display: inline-block;
  }

  .price-verify-icon:hover {
    color: #fff;
  }

  .modal-header-close-btn {
    position: relative;
  }

  .modal-header-close-btn .close {
    background: #fff none repeat scroll 0 0;
    border-radius: 20px;
    -webkit-box-shadow: -1px 1px 6px #848484;
    box-shadow: -1px 1px 6px #848484;
    color: red;
    margin-right: -26px;
    margin-top: -33px;
    opacity: 1;
    padding: 0 7px 5px;
  }

  .msc-payer-list {
    max-height: 300px;
    overflow: auto;
  }

  #priceVerify button.yellow-btn {
    width: 50% !important;
    border: 1px solid #eeb111 !important;
    height: 34px !important;
  }

  .pricing-alert-div {
    width: 280px;
    position: relative;
    margin: 10px 50px 10px 3px;
  }

  .highlighted {
    background-color: #eeb111 !important;
  }

  .open-cart-container {
    padding-top: 15px;
  }

  .open-cart {
    background-color: #fff;
    float: left;
    width: 100%;
  }

  .open-cart-details {
    padding: 5px 0;
  }

  .open-cart-details:hover {
    background-color: $rush-header-light;
    cursor: pointer;
  }

  .open-cart-details .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: transparent;
    border: none;
  }

  .cart-count {
    padding: 5px;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    line-height: 1;
    min-width: 20px;
  }

  .pad-l-20 {
    padding-left: 20px !important;
  }

  .open-items-container i {
    width: 100%;
    color: #eeb111;
  }

  .right-inner-addon {
    position: relative;
  }

  .right-inner-addon input {
    padding-right: 30px;
  }

  .right-inner-addon i {
    position: absolute;
    right: 0;
    padding: 10px 12px;
    pointer-events: none;
  }

  .open-items-row-expiry {
    background-color: #ffba026e !important;
  }

  .open-items-row-expiry:hover {
    background-color: $rush-header-light !important;
  }

  .note {
    position: absolute;
    top: 0;
    right: 0;
    height: 12px;
    width: 12px;
    cursor: pointer;
  }

  .note:after {
    content: "";
    // position: absolute;
    // top: 0;
    // right: 0;
    width: 0;
    height: 0;
    display: block;
    border-left: 12px solid transparent;
    border-top: 12px solid #af8208;
  }

  .couponPrice {
    padding: 0 10px !important;
  }

  .couponRedText {
    padding-top: 15px !important;
    color: red !important;
  }

  .couponRedText b,
  .couponRedText p,
  .couponRedText span {
    padding: 0 !important;
    margin: 0 !important;
  }

  .couponPrice-product-price {
    height: 215px !important;
  }

  .my-cart-list-button-header {
    padding: 12px 15px;
  }

  input[type="text"]::-ms-clear {
    display: none;
  }

  .close-quote input {
    opacity: 1 !important;
  }

  .close-quote label {
    margin-left: 8px !important;
    position: relative;
    top: -6px;
  }

  .disablehyperlink {
    pointer-events: none;
    cursor: not-allowed !important;
    color: #d3d3d3;
  }

  a.disablehyperlink {
    border: none !important;
  }

  .suggestedSelling-range-text-box {
    padding-right: 11%;
    position: relative;
    width: 35% !important;
  }

  .suggestedSellingCloseReason {
    padding-left: 5px;

    :first-child {
      padding-top: 5px;
    }
  }

  .suggestedSellingCloseReason input {
    opacity: 1 !important;
  }

  .suggestedSellingCloseReason label {
    margin-left: 8px !important;
    position: relative;
    top: -6px;
  }

  select::-ms-value {
    background: 0 0;
    color: #555;
  }

  .disablehyperlink {
    pointer-events: none;
    cursor: not-allowed !important;
    color: #d3d3d3;
  }

  a.disablehyperlink {
    border: none !important;
  }

  .terms_p {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: #4d4d4d;
    text-align: justify;
    margin-right: 5%;
  }

  .eula-agreement {
    text-align: justify !important;
    resize: none !important;
  }

  .suggested-selling-slider-arrow span {
    font-weight: 700;
    font-size: 30px;
    cursor: pointer;
  }

  .suggested-Selling-btn-add-to-cart {
    margin-right: 5px;
  }

  .refresh-data-text {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    position: relative;
    top: -5px;
  }

  .leftRs {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    -webkit-box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.3);
    border-radius: 999px;
    left: 0;
  }

  .rightRs {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    -webkit-box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.3);
    border-radius: 999px;
    right: 0;
  }

  @media only screen and (max-width: 500px) {
    .oredr-holder span {
      font-size: 13px;
    }
  }

  .pad-l-15 {
    padding-left: 15px !important;
  }

  .pad-t-10 {
    padding-top: 10px !important;
  }

  .center-text {
    text-align: center;
    padding-top: 20px;
  }

  h4.center-text {
    font-weight: 700;
  }

  .error-image-wrapper {
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .error-image-wrapper img {
    width: 55%;
    margin: 0 auto;
    display: block;
  }

  .pb-30 {
    padding-bottom: 30px;
  }

  #declineReasonPopover>.popover.top>.arrow {
    margin-left: -50px !important;
  }

  #declineReasonPopover>.popover.top.in {
    margin-left: 40px !important;
  }

  #lineItemNotesModal ul {
    padding: 0;
  }

  #lineItemNotesModal li {
    padding: 5px 10px;
    list-style-type: none;
    border: 1px solid lightgrey;
  }

  #lineItemNotesModal li.active {
    background-color: rgba(255, 186, 2, 0.431373);
  }

  #lineItemNotesModal li:hover {
    background-color: $rush-header-light;
  }

  .clickable {
    cursor: pointer !important;
  }

  .imageFeedbackForm {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    text-align: left;
    background-color: #d3d3d3;
    border: 1px solid #555;
  }

  .margintop-12p {
    margin-top: 12px;
  }

  .marginright-12p {
    margin-right: 12px;
  }

  .margintop-7 {
    margin-top: 7.5px !important;
  }

  .marginbottom-0 {
    margin-bottom: 0 !important;
  }

  .noDisplay {
    display: none;
  }

  .no-first-row-top-border tr:first-child td {
    border-top: 0;
  }

  .no-table-border tr td {
    border: 0;
  }

  .quote-count {
    background: #eeb111;
    border-radius: 20px;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-size: 12px;
    font-weight: 800;
    line-height: 1.6em;
    text-align: center;
    min-width: 20px;
  }

  .subLoader {
    background-image: url(../img/countLoader.gif) !important;
    height: 22px;
    width: 22px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% auto;
    border-radius: 20px;
  }

  .quoteCountLoader {
    background-image: url(../img/countLoader.gif) !important;
    height: 22px;
    width: 22px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% auto;
    border-radius: 20px;
  }

  .default-cart {
    background: #eeb111;
  }

  .search-icon {
    position: absolute;
    top: 2px;
    right: 20px;
  }

  .empty-part-search-text {
    font-size: 1.25rem;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .no-part-found-box-outer-wrapper {
    margin: 30px 0px;
  }

  .no-part-found-box-inner-wrapper {
    margin: 40px 0px;
  }

  .no-part-found-option-box {
    padding-left: 100px;

    h5 {
      u {
        font-size: 2rem;
      }
    }

    ul {
      li {
        font-size: 1.5rem;
      }
    }
  }

  .modal-header .close {
    background: #fff none repeat scroll 0 0;
    border-radius: 20px;
    box-shadow: -1px 1px 6px #848484;
    color: #ed1c24;
    opacity: 1;
    padding: 0 7px 5px;
    margin: -0.75rem -0.75rem 0 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .favouritepart-list-icon-nd-text {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;

    h1,
    h6 {
      text-align: center;
      display: block;

      i {
        text-align: center;
        display: block;
      }
    }
  }

  .favouriote-toggle-box-for-partlist {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;

    h1,
    h6 {
      text-align: center;
      display: block;

      i {
        text-align: center;
        display: block;
      }
    }
  }

  .favoritePart-detail-toggle-box-for-partlist {
    position: absolute;
    top: 20px;
    right: 10px;

    h1,
    h6 {
      text-align: center;
      display: block;

      i {
        text-align: center;
        display: block;
      }
    }
  }

  .favoritePart-alternat-detail-toggle-box-for-partlist {
    position: absolute;
    bottom: 150px;
    right: 30px;

    h4,
    h6 {
      display: block;
      text-align: center;

      i {
        text-align: center;
        display: block;
      }
    }
  }

  td.pricingContainer div.popover-body {
    padding: 0 !important;
  }

  td.pricingContainer li.list-group-item {
    width: 100% !important;
    padding: 5px 0 !important;
  }

  div.pricingContainer .popover {
    max-width: 320px !important;
    z-index: 1000 !important;
  }

  .popoverContainer {
    max-height: 200px !important;
    overflow-y: auto !important;
    width: 320px !important;
  }

  .popover {
    max-width: fit-content;
  }

  .couponPopover {
    width: 320px !important;
  }

  div.pricingContainer div.popover-body {
    padding: 0 !important;
  }

  div.pricingContainer li.list-group-item {
    width: 100%;
    padding: 5px 0;
  }

  a.popoverlink {
    cursor: pointer;
  }

  a.popoverlink:hover {
    text-decoration: underline !important;
  }

  .popoverlabels {
    padding: 0 5px 0 5px !important;
  }

  .popovervalue {
    padding: 0 5px 0 5px !important;
    text-align: right;
  }

  .popoverCouponHeader {
    width: 100%;
    padding: 5px 0;
    background: #eeb111 !important;
  }

  .favoritePart-checkBox {
    float: right;
    display: block;
  }

  .f-f-box {
    position: absolute;
    right: 2px;
    top: 5px;
  }

  .favorite-addToCart-btn {
    position: absolute;
    top: 50px;
  }

  .order-history-date-btn {
    background-color: white;
    width: 60px;
    display: inline-block;
    text-align: -webkit-center;
  }

  .order-history-date-btn label {
    background-color: black;
    color: white;
    text-align: center;
  }

  .order-history-date-btn input {
    vertical-align: middle;
    width: 60px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    padding: 0px;
    text-align: center;
  }

  input.typeahead-pre-results+ngb-typeahead-window ngb-highlight {
    white-space: pre-wrap;
  }

  #partDetails_div_SAPCuratedNote div {
    white-space: pre;
  }

  .proactivechat-position {
    position: fixed;
    bottom: 100px;
    right: 0;
    z-index: 2000;
  }

  @media only screen and (max-width: 767px) {
    .proactivechat-position {
        bottom: 119px !important;
    }
}

.dev-border {
  border: #000;
  border-style: solid;
  border-width: .1px;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

// css for icon on shopping basket for homePG and Parts DeatailPage
.shopping-basket-icon-homepg {
  position: absolute;
  padding: 3px;
  border-radius: 20px;
  background: #ed1c24;
  font-size: 11px;
  color: #fff;
  top: 120px;
  min-width: 20px;
}

.btn-shopping-basket-icon-homepg{
  position: relative;
}

.btn-shopping-basket-icon-homepg .shopping-basket-icon-homepg{
  top: -11px;
  right: -10px;
}

.shopping-basket-icon {
  position: absolute;
  padding: 3px;
  border-radius: 20px;
  background: #ed1c24;
  font-size: 11px;
  color: #fff;
  top: -8px;
  min-width: 20px;
}

.form-editable {
  background-color: $rush-header-light;
}

.delivery-options-tooltip .tooltip-inner {
  background-color: $rush-light;
  text-align: left;
}

.option-disabled {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: 0.4;
}

.number-of-loyalty-accounts {
  position: absolute;
  padding: 3px;
  border-radius: 20px;
  background: #ed1c24;
  font-size: 11px;
  color: #fff;
  min-width: 20px;
  top: -11px;
}
