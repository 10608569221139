.pagination {
  margin: 10px 0 0;

  > li {
    margin: 0 1px !important;
    cursor: pointer;

    > a.dark, > span.dark {
      color: #ffffff;
      background-color: transparent;
      margin: 0 1px;
      border: 0px solid #ddd;
      border-radius: 50%;
      padding: 6px 12px;

      &:focus, &:hover {
        // color: #AB711B;
        background-color: #eee !important;
        color: #000 !important;
      }
    }

    > a, > span {
      color: #000;
      background-color: transparent;
      margin: 0 1px;
      border: 0px solid #ddd;
      border-radius: 50%;
      padding: 6px 12px;

      &:focus, &:hover {
        color: #AB711B;
      }
    }

    &:first-child, &:last-child {
      > a.dark, > span.dark {
        border-radius: 50%;
      }
      > a, > span {
        border-radius: 50%;
      }
    }

    &.active {
      > a.dark, > span.dark {
        &, &:focus, &:hover {
          background-color: #eee !important;
          color: #000 !important;
        }
      }
      > a, > span {
        &, &:focus, &:hover {
          background-color: #eee !important;
          color: #000 !important;
        }
      }
    }

    &.disabled {
      > a.dark, > span.dark {
        &, &:focus, &:hover {
          color: #BBB !important;
          cursor: not-allowed;
          background-color: transparent !important;
        }
      }
      > a, > span {
        &, &:focus, &:hover {
          color: #777 !important;
          cursor: not-allowed;
          background-color: transparent !important;
        }
      }
    }
  }

  &.pagination-sm {
    > li {
      > a.dark, > span.dark {
        padding: 4px 8px;
        font-size: inherit;
      }
      > a, > span {
        padding: 4px 8px;
        font-size: inherit;
      }
    }
  }
}
