// Making the Bootstrap width and height utilities available for responsive breakpoints

@each $breakpoint in map-keys($grid-breakpoints) {
    
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (width: w, height: h) {
            @each $size, $length in $sizes {
                .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
            }
        }
    }
}
