.nav > li > a {
  display: block;
  position: relative;
}

.nav-tabs {
  > .nav-item {
    > .nav-link {
      border-radius: 0;
      border-top: 2px solid transparent;
      margin-right: 0;
      color: #000;
      font-family: $rush-font-bold;
      font-size: 1.25rem; // Matches h5 size
      &.active {
        border-top: 2px solid $rush-gold;
      }
    }
  }
  + .tab-content > .tab-pane {
    background-color: #fff;
    border-color: $nav-tabs-border-color;
    border-style: solid;
    border-width: 0 1px 1px;
  }
}

.nav-links {
  padding: 10px 0px;

  > .nav-item {
    // text-decoration-color is not available for IE.
    // This approach uses :before overlayed with whitespace to display an underline with a different color.
    > .nav-link {
      padding: 0.05rem 0.5rem;
      position: relative;
      color: #495057;
      &.active:before,
      &:hover:before {
        color: $rush-gold;
      }
      &:before {
        position: absolute;
        left: 0.5rem;
        right: 0.5rem;
        margin-top: 2px;
        content: "\00a0                                              ";
        white-space: pre;
        text-decoration: underline;
        overflow: hidden;
      }
    }
    &:first-child {
      > .nav-link {
        padding-left: 0;
        &:before {
          left: 0;
        }
      }
    }
    &:not(:first-child) {
      border-left: 1px solid black;
    }
  }
}
.logo-image {
  max-height: 60px;
  max-width: 370px;
}
