.modal-content {
  border-radius: 0;
  border-width: 0;
}

.modal-content .table {
  margin-bottom: 0px;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.modal-footer {
  border-top: none;
  padding-top: 0;
}

.modal-title {
  font-family: $rush-font-bold;
}

.modal-content .close {
  background: #fff none repeat scroll 0 0;
  border-radius: 20px;
  box-shadow: -1px 1px 6px #848484;
  color: $rush-red;
  opacity: 1;
  padding: 0 7px 5px;
  margin: -0.75rem -0.75rem 0 0;

  &:not(:disabled):not(.disabled) {
    &:hover, &:focus {
      color: $rush-red;
      text-decoration: inherit;
      opacity: inherit;
    }
  }
}

.modal-scrollable-content {
  max-height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .modal-body {
    overflow-y: auto;
  }
}

ngb-modal-window[aria-hidden="true"] {
  display: none !important;
}

ngb-modal-backdrop:not(:last-of-type) {
  display: none !important;
}
