/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-width: 320px) and (max-width: 480px) {

  .my-cart-btn {
    margin-right: 0px;
  }

  .reset-right-area-left-padding {
    padding-left: 15px !important;
  }

  .search-holder .sub-title {
    margin-top: 0;
  }
  
  #panelRelatedParts, #panelAlternateParts {
    position: initial;
  }

  .related-alternate-parts-pagination {
    position: initial;
  }
}
/********** iPad 3 **********/

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
}
@media only screen and (min-width: 360px) and (max-width: 640px) {
  #panelRelatedParts, #panelAlternateParts {
    position: initial;
  }

  .related-alternate-parts-pagination {
    position: initial;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .reset-right-area-left-padding {
    padding-left: 15px !important;
  }

  .oredr-holder .text-align-left {
    margin: 10px 0 40px;
  }
}
/* iPhone 4 ----------- */

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: landscape) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}
/* iPhone 5 ----------- */

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* iPhone 6 ----------- */

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  
}
/* iPhone 6+ ----------- */

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  
}
/* Samsung Galaxy S3 ----------- */

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* Samsung Galaxy S4 ----------- */

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}
/* Samsung Galaxy S5 ----------- */

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
}

@media only screen and (min-width: 320px) and (max-width: 640px) {
  .search-holder .sub-title {
    margin-top: 0;
  }

  .reset-right-area-left-padding {
    padding-left: 15px !important;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 2000px) {
}

@media only screen and (min-width: 2001px) and (max-width: 3000px) {
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
}
/* BOOTSTARP BRACKPOINT*/

@media (min-width: 992px) {

  .my-cart-btn span {
    right: 7px;
    top: 17px;
  }
}

@media screen and (max-width: 767px) {
}

@media (max-width: 767px) {
}

@media screen and (min-width: 768px) {
}

@media (max-device-width: 480px) and (orientation: landscape) {
}
