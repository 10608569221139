@import "overwrite_bootstrap/forms";
@import "overwrite_bootstrap/modal";
@import "overwrite_bootstrap/nav";
@import "overwrite_bootstrap/navbar";
@import "overwrite_bootstrap/pagination";

.accordion {
  > .card {
    &,
    &:first-of-type,
    &:last-of-type {
      border: 1px solid $rush-gold;
      border-radius: 0.25rem;
      word-wrap: normal;
    }
  }

  &.accordion-sm > .card > .card-header {
    padding: 0;
    word-wrap: normal;

    .btn-link {
      color: inherit;
      font-family: $rush-font;
      width: 100%;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.accordion .white-panel .card-header {
  background-color: #f5f5f5 !important;
  color: #000;
  border-top: 1px gray;
}

.accordion .white-panel {
  &,
  &:first-of-type,
  &:last-of-type {
    border-top: 1px solid gray;
    border-left: none;
    border-right: none;
    border-bottom: none;
    margin-bottom: none;
    word-wrap: normal;
    background-color: #f5f5f5;
  }

  .collapse-arrow {
    float: right;
    font-size: large;
  }

  .bg-header {
    border-bottom: none;
  }

  .btn-link {
    background-color: #f5f5f5 !important;
    color: inherit;
    font-family: "TradeGothicLT-CondEighteen";
    width: 100%;
    padding-left: 0;

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: #f5f5f5;
      box-shadow: none;
    }

    &[aria-expanded="true"] {
      background-color: #f5f5f5;
    }

    &[aria-expanded="false"] {
      background-color: #f5f5f5;
    }
  }
}

.badge {
  font-weight: normal;
  font-family: $rush-font-bold;
}

.breadcrumb {
  background-color: transparent;
  padding: 8px 0;
  margin: 0;

  a {
    color: #292929;
  }

  .active {
    color: #000;
    font-weight: bold;
  }
}

.btn {
  font-family: $rush-font-bold;
}

.ngb-highlight {
  font-family: $rush-font-bold;
  font-weight: normal !important;
}

.popover {
  font-family: inherit;
  font-size: 1rem;
}

.result-show {
  width: 20% !important;
}

.rounded-lg {
  border-radius: $border-radius-lg !important;
}
.rounded-top-lg {
  border-top-left-radius: $border-radius-lg !important;
  border-top-right-radius: $border-radius-lg !important;
}
.rounded-right-lg {
  border-top-right-radius: $border-radius-lg !important;
  border-bottom-right-radius: $border-radius-lg !important;
}
.rounded-bottom-lg {
  border-bottom-right-radius: $border-radius-lg !important;
  border-bottom-left-radius: $border-radius-lg !important;
}
.rounded-left-lg {
  border-top-left-radius: $border-radius-lg !important;
  border-bottom-left-radius: $border-radius-lg !important;
}

small,
.small {
  font-size: 80% !important;

  line-height: 0.8;
}

.table-holder {
  background-color: #fffef4;
  border: 1px solid #ccc;
  padding: 10px;
}

.tooltip-inner {
  max-width: none;
  color: inherit;
}

.whitebg {
  background-color: #fff !important;
  color: #555;
}

.IEtablepadding {
  padding: 0.4rem !important;
}

.accordion .checkout-cart .card-header {
  background-color: #f5f5f5 !important;
  color: #000;
}

.accordion .checkout-cart {
  background-color: #f5f5f5 !important;
  border: none !important;
  > .card {
    &,
    &:first-of-type,
    &:last-of-type {
      //border: 1px solid lightgray;
      border: none !important;
      word-wrap: normal;
      background-color: #f5f5f5 !important;
    }
  }

  .btn-link {
    background-color: #f5f5f5 !important;
    color: inherit;
    font-family: "TradeGothicLT-CondEighteen";
    width: 100%;

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: #f5f5f5;
    }

    &[aria-expanded="true"] {
      background-color: #f5f5f5;
    }

    &[aria-expanded="false"] {
      background-color: #f5f5f5;
    }
  }
}

.accordion .basket-list .card-header {
  background-color: #f5f5f5 !important;
  color: #000;
}

.accordion .basket-list {
  border: 1px solid lightgray !important;
  > .card {
    &,
    &:first-of-type,
    &:last-of-type {
      border: 1px solid lightgray !important;

      border-radius: 0.25rem;
      word-wrap: normal;
    }
  }

  .card-header {
    padding: 0;
    word-wrap: normal;
  }

  .btn-link {
    background-color: white;
    color: inherit;
    font-family: $rush-font;
    width: 100%;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &[aria-expanded="true"] {
      background-color: $rush-gold;
    }
  }
}

ngb-pagination ul {
  justify-content: center;
}
